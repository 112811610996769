import BaseStyles from '../base.js';
import merge from 'deepmerge';

export class Dhigurah extends BaseStyles {

  getPalette() {
    return merge(super.getPalette(), {
      /* Colors Palette */
      primary: '#000000',
      primaryContrast: '#D5B373',
      accent: '#D5B373',
      accentContrast: '#000000',
    });
  }

  getStyles() {
    const palette = this.getPalette();
    return merge(super.getStyles(), {
      /* Styles */
      loginScreen: {
        splashScreenSpinner: {
          marginBottom: -180
        }
      },
      signUpScreen: {
        text: {
          textAlign: 'center',
          color: 'white',
          paddingVertical: 16,
          maxWidth: '90%'
        },
        dynamicForm: {
          textColor: {
            color: 'white',
          },
          input: {
            color: 'white',
            borderColor: 'white'
          }
        }
      }
    });
  }

  getTheme() {
    return merge(super.getTheme(), {
      /* Theme properties */
      title: 'Sands Grand',
      slogan: 'Dhigurah, Maldives',
      //splashVideo: require('./assets.dhigurah/SplashVideo.mp4'),
      splashScreen: require('./assets/SplashScreen.png'),
      splashImage: require('./assets.dhigurah/SplashScreen.png'),
      logo: require('./assets.dhigurah/Logo.png'),
      logoColor: require('./assets.dhigurah/LogoColor.png'),
      islandBackground: require('./assets.dhigurah/TheIslandBackground.png'),
      assets: {}
    });
  }

}

export class Thulusdhoo extends BaseStyles {
  getPalette() {
    return merge(super.getPalette(), {
      /* Colors Palette */
      primary: '#000000',
      primaryContrast: '#D5B373',
      accent: '#D5B373',
      accentContrast: '#000000',
    });
  }

  getStyles() {
    const palette = this.getPalette();
    return merge(super.getStyles(), {
      /* Styles */
      loginScreen: {
        splashScreenSpinner: {
          marginBottom: -180
        }
      },
      signUpScreen: {
        text: {
          textAlign: 'center',
          color: 'white',
          paddingVertical: 16,
          maxWidth: '90%'
        },
        dynamicForm: {
          textColor: {
            color: 'white',
          },
          input: {
            color: 'white',
            borderColor: 'white'
          }
        }
      }
    });
  }

  getTheme() {
    return merge(super.getTheme(), {
      /* Theme properties */
      title: 'Sands Exotic',
      headline: 'Thulusdhoo',
      slogan: 'Thulusdhoo, Maldives',
      //splashVideo: require('./assets.thulusdhoo/SplashVideo.mp4'),
      splashScreen: require('./assets/SplashScreen.png'),
      splashImage: require('./assets.thulusdhoo/SplashScreen.png'),
      logo: require('./assets.thulusdhoo/Logo.png'),
      logoColor: require('./assets.thulusdhoo/LogoColor.png'),
      islandBackground: require('./assets.thulusdhoo/TheIslandBackground.png'),
      assets: {}
    });
  }
}

export class Mathiveri extends BaseStyles {
  getPalette() {
    return merge(super.getPalette(), {
      /* Colors Palette */
      primary: '#000000',
      primaryContrast: '#D5B373',
      accent: '#D5B373',
      accentContrast: '#000000',
    });
  }

  getStyles() {
    const palette = this.getPalette();
    return merge(super.getStyles(), {
      /* Styles */
      loginScreen: {
        splashScreenSpinner: {
          marginBottom: -180
        }
      },
      signUpScreen: {
        text: {
          textAlign: 'center',
          color: 'white',
          paddingVertical: 16,
          maxWidth: '90%'
        },
        dynamicForm: {
          textColor: {
            color: 'white',
          },
          input: {
            color: 'white',
            borderColor: 'white'
          }
        }
      }
    });
  }

  getTheme() {
    return merge(super.getTheme(), {
      /* Theme properties */
      title: 'Sands Inn',
      headline: 'Mathiveri',
      slogan: 'Mathiveri, Maldives',
      //splashVideo: require('./assets.thulusdhoo/SplashVideo.mp4'),
      splashScreen: require('./assets/SplashScreen.png'),
      splashImage: require('./assets.mathiveri/SplashScreen.png'),
      logo: require('./assets.mathiveri/Logo.png'),
      logoColor: require('./assets.mathiveri/LogoColor.png'),
      islandBackground: require('./assets.mathiveri/TheIslandBackground.png'),
      assets: {}
    });
  }

}

export default Dhigurah;
